export enum KeyValue {
    ArrowDown = 'ArrowDown',
    ArrowLeft = 'ArrowLeft',
    ArrowRight = 'ArrowRight',
    ArrowUp = 'ArrowUp',
    Backspace = 'Backspace',
    Delete = 'Delete',
    End = 'End',
    Enter = 'Enter',
    Escape = 'Escape',
    Home = 'Home',
    PageDown = 'PageDown',
    PageUp = 'PageUp',
    Spacebar = 'Space',
    Tab = 'Tab',
    Unidentified = 'Unidentified',
}
