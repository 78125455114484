import 'core-js/stable';

import { DEFAULT_CURRENCY_CODE } from '../defaultCurrency';
import { DEFAULT_LANGUAGE_CODE } from '../defaultLanguage';

import { addStyleProperties } from './addStyleProperties';
import {
    APP_CONTAINER_ID,
    CONFIGURATION_FILENAME,
    LOCALIZATION_FILENAME,
    ONE_TICK,
    PLATFORM_PRIMARY_API_URL,
} from './bootstrapConstants';
import { createAppContainer } from './createAppContainer';
import { loadJson } from './loadJson';
import { parseFeatureFlagOption } from './utils';

const version = __VERSION__;

// @ts-expect-error $TSFixMeError: Element implicitly has an 'any' type because type 'typeof globalThis' has no index signature.
global.Mews = global.Mews || {};

// @ts-expect-error $TSFixMeError: Element implicitly has an 'any' type because type 'typeof globalThis' has no index signature.
global.Mews.D = function D(configurationIds: $TSFixMe, dataBaseUrl: $TSFixMe) {
    const initDistributor = () =>
        // @ts-expect-error $TSFixMeError: Element implicitly has an 'any' type because type 'typeof globalThis' has no index signature.
        global.Mews.Distributor({ configurationIds, openElements: '.distributor' }, null, { dataBaseUrl });
    // @ts-expect-error $TSFixMeError: Element implicitly has an 'any' type because type 'typeof globalThis' has no index signature.
    if (global.readyState === 'loading') {
        global.addEventListener('DOMContentLoaded', initDistributor);
    } else {
        initDistributor();
    }
};

// @ts-expect-error $TSFixMeError: Element implicitly has an 'any' type because type 'typeof globalThis' has no index signature.
global.Mews.Distributor = function Distributor(
    window: $TSFixMe,
    customOptions: $TSFixMe,
    loadCallback = () => {},
    // hidden override options
    {
        dataBaseUrl = PLATFORM_PRIMARY_API_URL,
        appUrl = null,
        configuration = null,
        localization = null,
        assetBaseUrl = null,
    } = {}
) {
    const {
        // app
        isStandalone,
        // settings
        hotelIds,
        configurationIds,
        adultCount,
        childCount,
        gtmContainerId,
        introVideoSrc,
        // features
        hideSpecialRequests,
        showRateCompare,
        competitors,
        // theme
        // @ts-expect-error $TSFixMeError: Initializer provides no value for this binding element and the binding element has no default value.
        theme: { primaryColor } = {},
        // initialState options
        startDate,
        endDate,
        language,
        currency,
        // bootstrap options
        openElements,
        // backwards compatibility options
        hotelId,
    } = customOptions;
    const appContainer = createAppContainer(window, openElements, { isStandalone });
    appContainer.onLoad(loadApp);
    window.setTimeout(() => window.document.body.appendChild(appContainer.getElement()), ONE_TICK);
    function loadApp() {
        const toLowerCase = (str: string | undefined | null) =>
            str != null ? String.prototype.toLowerCase.call(str) : null;

        const configurationUrl = `${dataBaseUrl}/${CONFIGURATION_FILENAME}`;
        Promise.resolve()
            .then(async () => configuration || loadJson(configurationUrl))
            .then(async (serverConfiguration: $TSFixMe) => {
                const { platformApiUrl } = serverConfiguration;
                const localizationUrl = `${platformApiUrl}/${LOCALIZATION_FILENAME}`;
                return Promise.all([
                    Promise.resolve().then(() => serverConfiguration),
                    Promise.resolve().then(async () => localization || loadJson(localizationUrl)),
                ]);
            })
            .then(([serverConfiguration, globalization]: [$TSFixMe, $TSFixMe]) => {
                const queryString = new URLSearchParams(window.location.search);

                const logsFromLoader = [];
                const queryMewsRedirect = queryString.get('mewsRedirect');
                if (queryMewsRedirect != null) {
                    let redirectURL = null;
                    try {
                        redirectURL = new URL(decodeURIComponent(queryMewsRedirect));
                    } catch (e) {
                        logsFromLoader.push({
                            type: 'mews-redirect-parse-failed',
                            message: 'Could not parse mewsRedirect query parameter',
                            data: { origin: window.location.origin, mewsRedirect: queryString.get('mewsRedirect') },
                        });
                    }
                    if (
                        redirectURL != null &&
                        [...serverConfiguration.platformUrls, window.location.origin].includes(redirectURL.origin)
                    ) {
                        const originURL = new URL(window.location.href);
                        const params = new URLSearchParams(originURL.search);
                        params.delete('mewsRedirect');
                        originURL.search = params.toString();
                        window.history.replaceState(null, null, originURL.toString());

                        window.location = redirectURL.href;
                        return;
                    }
                    logsFromLoader.push({
                        type: 'unautorized-redirect',
                        message: 'Distributor widget tried to redirect outside alowed origins',
                        data: {
                            origin: window.location.origin,
                            redirectURL: redirectURL?.href,
                        },
                    });
                }

                const shouldOpen =
                    queryString.get('mewsDistributorOpened') != null ||
                    (hotelId && queryString.get('mewsEnterpriseId') === hotelId);
                const initialState = {
                    app: {
                        availabilityBlockIdFromQuery: queryString.get('mewsAvailabilityBlockId'),
                        languageCode: queryString.get('language') || language || DEFAULT_LANGUAGE_CODE,
                        languageCodeFromQuery: queryString.get('language'),
                        featureFlagDebug: parseFeatureFlagOption(queryString.get('ffdebug')),
                        currencyCode: currency || DEFAULT_CURRENCY_CODE,
                        currencyCodeFromQuery: queryString.get('currency'),
                        cityIdFromQuery: toLowerCase(queryString.get('mewsCityId')),
                        routeFromQuery: toLowerCase(queryString.get('mewsRoute')),
                        roomFromQuery: toLowerCase(queryString.get('mewsRoom')),
                        hotelFromQuery: toLowerCase(queryString.get('mewsHotel')),
                        startDateFromQuery: toLowerCase(queryString.get('mewsStart')),
                        endDateFromQuery: toLowerCase(queryString.get('mewsEnd')),
                        voucherCodeFromQuery: queryString.get('mewsVoucherCode'),
                        reservationGroupIdFromQuery: queryString.get('mewsReservationGroupId'),
                        reservationIdsFromQuery: queryString.get('mewsReservationIds')?.split(',') || [],
                        paymentCardIdFromQuery: queryString.get('mewsPaymentCardId'),
                        propertyReturnUrlFromQuery: queryString.get('mewsPropertyReturnUrl'),
                        adultCountFromQuery: queryString.has('mewsAdultCount')
                            ? parseInt(queryString.get('mewsAdultCount') ?? '', 10)
                            : null,
                        childCountFromQuery: queryString.has('mewsChildCount')
                            ? parseInt(queryString.get('mewsChildCount') ?? '', 10)
                            : null,
                    },
                    resourceOrder: {
                        startDate,
                        endDate,
                        promotedServices: [],
                    },
                };
                const { ...appConfiguration } = serverConfiguration;
                const finalAssetBaseUrl = assetBaseUrl || serverConfiguration.assetBaseUrl;
                let finalHotelIds: $TSFixMe = [];
                if (Array.isArray(hotelIds)) {
                    finalHotelIds = hotelIds.map(toLowerCase);
                } else if (hotelId) {
                    finalHotelIds.push(toLowerCase(hotelId));
                }
                const hotels = {};
                // @ts-expect-error $TSFixMeError: Parameter 'id' implicitly has an 'any' type.
                finalHotelIds.forEach((id) => {
                    // @ts-expect-error $TSFixMeError: Element implicitly has an 'any' type because expression of type 'any' can't be used to index type '{}'.
                    hotels[id] = {
                        settings: {
                            defaultAdultCount: adultCount,
                            defaultChildCount: childCount,
                        },
                        features: {
                            showSpecialRequestsField: !hideSpecialRequests,
                            rateCompare: {
                                isEnabled: !!showRateCompare,
                                competitors,
                            },
                        },
                    };
                });
                const appOptions = {
                    contextWindow: window,
                    closeApp: appContainer.close,
                    configuration: {
                        app: {
                            ...appConfiguration,
                            assetBaseUrl: finalAssetBaseUrl,
                            isStandalone: !!isStandalone,
                        },
                        settings: {
                            hotelIds: finalHotelIds,
                            configurationIds,
                            googleTagManagerContainerId: gtmContainerId,
                            introVideoSrc,
                        },
                        hotels,
                        theme: {
                            primaryColor,
                        },
                    },
                    element: `#${APP_CONTAINER_ID}`,
                    globalization,
                    customOptions,
                    logsFromLoader,
                };
                if (isStandalone || shouldOpen) {
                    appContainer.open();
                }
                appContainer.loadReactDevtools();
                const finalAppUrl = appUrl || getAppUrl(serverConfiguration);
                appContainer.loadApp(finalAppUrl, appOptions, initialState, (distributor: $TSFixMe) => {
                    // wrap opener
                    const { open } = distributor;
                    distributor.open = () => {
                        // Redirect non-https domains to standalone
                        if (!isSecureContextWithFallback()) {
                            const { platformPrimaryUrl } = serverConfiguration;
                            const ids = configurationIds || finalHotelIds;
                            const configurationQuery = ids.join(';');
                            window.location.replace(`${platformPrimaryUrl}/distributor/${configurationQuery}`);
                        } else {
                            open();
                            appContainer.open();
                        }
                    };
                    // call custom callback
                    if (typeof loadCallback === 'function') {
                        try {
                            // @ts-expect-error $TSFixMeError: Expected 0 arguments, but got 1.
                            loadCallback(distributor);
                        } catch (e) {
                            console.error(e.stack); // eslint-disable-line no-console
                        }
                    }
                });
            })
            .catch(() => {
                createErrorPage({
                    message: 'Loading of the booking engine has failed',
                    hasTryAgain: true,
                });
            });
    }
    // @ts-expect-error $TSFixMeError: Binding element 'assetUrl' implicitly has an 'any' type.
    function getAppUrl({ assetBaseUrl: assetUrl }) {
        const appFilename = 'distributor-app.js';
        return `${assetUrl}/${version}/${appFilename}`;
    }
    function isSecureContextWithFallback() {
        // Fallback for browsers without isSecureContext support
        if (typeof window.isSecureContext === 'undefined') {
            return isHttps();
        }
        return window.isSecureContext;
    }
    function isHttps() {
        return window.location.protocol === 'https:';
    }
    function createErrorPage({ message, hasTryAgain }: $TSFixMe) {
        const doc = appContainer.getDocument();
        doc.body.style.cursor = 'default';
        addStyleProperties(appContainer.getElement(), { opacity: 1 });
        const wrapper = doc.createElement('div');
        wrapper.style.textAlign = 'center';
        const title = doc.createElement('h1');
        title.innerText = message;
        wrapper.appendChild(title);
        const close = doc.createElement('a');
        close.href = '';
        close.innerText = 'Close';
        close.addEventListener('click', (e: $TSFixMe) => {
            e.preventDefault();
            appContainer.close();
        });
        wrapper.appendChild(close);
        if (hasTryAgain) {
            const reload = doc.createElement('a');
            reload.href = '';
            reload.innerText = 'Try again';
            reload.style.marginLeft = '14px';
            reload.addEventListener('click', (e: $TSFixMe) => {
                e.preventDefault();
                doc.body.innerHTML = '';
                loadApp();
            });
            wrapper.appendChild(reload);
        }
        doc.body.appendChild(wrapper);
    }
}.bind(null, window);
